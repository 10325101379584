







































import AuthLayout from '@/views/AuthViews/AuthLayout.vue';
import PasswordInputExtended from '@/components/PasswordInputExtended.vue';
import BaseLoader from '@/components/core/BaseLoader.vue';
import { getRegistrationData, signUpVerify } from '@/api/Auth';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import { NavigationGuardNext, Route } from 'vue-router';
import { token, isSalesType } from '@/helpers/authHelpers';
import { http } from '@/api/Connect';
import SecureLS from 'secure-ls';

const removeToken = (): void => {
  const ls = new SecureLS();
  ls.remove('tokenKey');
  http.removeToken();
};

export default {
  name: 'AccountVerifyView',
  components: { AuthLayout, PasswordInputExtended, BaseLoader },
  data(): any {
    return {
      formValid: true,
      email: '',
      password: '',
      isLoading: true,
      isSubmit: false
    };
  },
  computed: {
    hasData(): boolean {
      return [this.email, this.password].every((el) => el?.length);
    }
  },
  async mounted(): Promise<void> {
    try {
      if (isSalesType) {
        const { email } = await getRegistrationData(token);
        this.email = email;
      } else {
        const authToken = await this.signUpVerify();
        this.$store.commit('Onboarding/resetAccountInfo');
        await this.loginUser(authToken);
        this.redirectUserAfterLogin();
      }
    } catch (e) {
      errorToastMessage(e);
      const query = this.$route.query;
      delete query.token;
      this.$store.commit('Auth/logout');
      await this.$router.replace({ path: '/login', query });
    } finally {
      this.isLoading = false;
    }
  },
  async beforeRouteEnter(
    to: Route,
    from: Route,
    next: NavigationGuardNext
  ): Promise<void> {
    if (!token) {
      next('/login');

      return;
    }

    removeToken();
    next();
  },
  methods: {
    async handleSubmit(): Promise<void> {
      try {
        this.isSubmit = true;
        const isFormValid = await this.$refs.form.validate();
        if (isFormValid) {
          const authToken = await this.signUpVerify();
          await this.loginUser(authToken);
          this.redirectUserAfterLogin();
        }
      } catch (e) {
        errorToastMessage(e);
      } finally {
        this.isSubmit = false;
      }
    },
    async signUpVerify(): Promise<string> {
      const { token: authToken } = await signUpVerify(
        token,
        true,
        this.password
      );
      return authToken;
    },
    async loginUser(token: string): Promise<void> {
      await this.$store.dispatch('Auth/authorize', token);
    },
    redirectUserAfterLogin(): void {
      const toastMessage = isSalesType
        ? 'Login complete.'
        : 'Email confirmed.';
      const query = this.$route.query;
      delete query.token;
      this.$router.replace({ path: '/home', query }, () =>
        this.$toast.success(toastMessage)
      );
    }
  }
};
